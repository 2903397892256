<template>
<div class="" style="width: 100%;">
    <div style="display: flex; flex-direction: row; gap: 15px;">
        <div style="display: flex; justify-content: space-evenly; gap: 15px; flex-direction: column; width: 50%;">
            <div style="display: flex; gap: 8px; align-items: center;">
                <div class="dateBox"></div>
                <div>{{$t("Customer.Appointments.token_not_yet_arrived")}}</div>
            </div>
            <div style="display: flex; gap: 8px; align-items: center; max-width: 200px;">
                <div class="dateBox" style="background-color: #01792a;"></div>
                <div>{{$t("Customer.Appointments.completed_tokens")}}</div>
            </div>
        </div>
        <div style="display: flex; justify-content: space-evenly; gap: 15px; flex-direction: column; width: 50% ;">

            <div style="display: flex; gap: 8px; align-items: center;">
                <div class="dateBox"  style="background-color: #1467bf;"></div>
                <div>{{$t("Customer.Appointments.tokens_arrived")}}</div>
            </div>
            <div style="display: flex; gap: 8px; align-items: center; max-width: 200px;">
                <div class="dateBox"  style="background-color: #b35b13;"></div>
                <div>{{$t("Customer.Appointments.seeing_doctor")}}</div>
            </div>
        </div>
    </div>
</div>
</template>

<script>



export default {
    name: 'allotedSlotBoxColorInfo',
    data: function() {
        return {
            todayAppointment: [],
            pageLoading: true,
            ongoingAppointment: [],
            bannerURL: '',
            viewDetailsModel: false,
            hospitalName: 'Asia Royal Hospital',
            hospitalLocation: 'Yangon',
            hospitalRegNumber: '1241424',
            phoneNumber: '+95 67980342453',
            reviews: '299',
            doctorCount: 56,
            fullDescription: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint. Lorem ipsum dolor sit amet consectetur adipisicing elit. Dolor, dolorem laborum. Corporis mollitia, aut, nihil animi sit adipisci vero voluptatem facere error hic at iure repellat cupiditate, officiis laborum sint.',
            readMore: false,
        };
    },
    computed: {
        textPreview() {
            return this.fullDescription.slice(0, 100) + '...';
        }
    },
    async mounted() {
    },
    methods: {
        goToOPDPage() {
            this.$router.push({
                name: 'OPDHome'
            })
        },
        toggleHospitalDetails() {
            this.viewDetailsModel = !this.viewDetailsModel;
        },
        closeBottomSheet() {
            this.viewDetailsModel = false;
        },
        descriptionPreview() {

        }
    },
};

</script>
<style  scoped>
.mainContainer {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-radius: 8px;
}
.dateBox {
        width: 32px;
        height: 32px;
        background: red;
        border-radius: 8px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        border: 1px solid #e0e0e0;
        background: white;
    }
</style>
    